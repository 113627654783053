import * as React from 'react';
import Card from '@mui/joy/Card';
import CardActions from '@mui/joy/CardActions';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Button from '@mui/joy/Button';
import { VerifiedUser } from '@mui/icons-material';
import { Backdrop, CircularProgress, TextField } from '@mui/material'
import AspectRatio from '@mui/joy/AspectRatio';
import Grid from '@mui/joy/Grid';
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import InfoIcon from '@mui/icons-material/Info';
import logo from './logo.png'
import axios from 'axios';
import moment from 'moment';
import NotFound from './empty-folder.png'

export default function CreditCardForm() {
  const [open, setOpen] = React.useState(false);
  const [openNotFound, setOpenNotFound] = React.useState(false);
  const [showInfo, setShowInfo] = React.useState(false)
  const [cin, setCin] = React.useState('')
  const [nDemande, setNAttestation] = React.useState('')
  const [cinValid, setCinValid] = React.useState(null)
  const [attestationValid, setAttestationValid] = React.useState(null)
  const [data, setData] = React.useState(null)
  const [expDate, setExpDate] = React.useState('')
  const [axp, setExp] = React.useState(false)

  const handleSubmit = e => {
    const headers = {
      'Content-Type': 'application/json'
    };
    if (cin !== '' && nDemande !== '') {
      setOpen(true)
      const data = {
        nDemande,
        cin
      }

      setCinValid(true)
      setAttestationValid(true)

      axios.post(`https://cciso.dyndns-server.com/iconnect/api/verifierDemande`, data, { headers })
        .then(res => {

          let expirationDate
          if (res.data.status === 200) {
            setOpen(false);
            setShowInfo(true)

            setData(res.data.data)
            const valableDate = moment(res.data.data.demande.dateValidation).format('DD-MM-YYYY')
            if (res.data.data.demande.type === "Attestation professionnelle") {
              expirationDate = moment(valableDate, 'DD MM YYYY').add(3, 'months').format('DD-MM-YYYY')
            } else {
              expirationDate = moment(valableDate, 'DD MM YYYY').format('31-12-YYYY')
            }

            setExpDate(expirationDate)

            if (moment().format('YYYYMMDD') > moment(expirationDate).format('YYYYMMDD')) {
              setExp(true)
            } else {
              setExp(false)
            }
            // setIsValid(true)
          } else if (res.data.status === 400) {
            // setIsValid(false)
            setTimeout(() => {
              setOpen(false)
              setOpenNotFound(true)
            }, 1000)
            setTimeout(() => {
              setCin('')
              setNAttestation('')
              setOpenNotFound(false)
              setShowInfo(false)
            }, 2000)
          }
        }).catch(err => {
          console.log(err)
        })
    } else {
      if (nDemande !== '') {
        setAttestationValid(true)
      } else {
        setAttestationValid(false)
      }

      if (cin !== '') {
        setCinValid(true)
      } else {
        setCinValid(false)
      }
    }
  }

  return (
    <>
      <div className={`${showInfo ? 'rowAfterSearch row search' : 'row search'}`}>

        <div className='col-12 text-center'>
          <img src={logo} className='widthLogo mb-3' alt='logo' />
        </div>
        <div className='col-md-2 col-xl-3'></div>
        <div className='col-md-8 col-xl-6'>
          <Card
            className=" shadow text-center"
            variant="outlined"
            sx={{
              maxWidth: '100%',
              width: '100%'
            }}
          >
            <div className="d-flex justify-content-center">
              <Typography className=" text-center" level="title-lg" startDecorator={<VerifiedUser />}>
                Vérification de l'authenticité d'une attestation
              </Typography>
            </div>
            <Divider inset="none" />
            <CardContent
            >
              <Grid container spacing={2} sx={{ flexGrow: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid xs={12} md={6}>
                  <FormControl >
                    <FormLabel>CIN N°</FormLabel>
                    <Input id='cin' value={cin} onChange={e => setCin(e.target.value)} />
                    {!cinValid && cinValid !== null && <span className='text-danger'>Le champ obligatoire</span>}
                  </FormControl>
                </Grid>
                <Grid xs={12} md={6}>
                  <FormControl >
                    <FormLabel>Numéro d'attestation</FormLabel>
                    <Input id='cin' value={nDemande} onChange={e => setNAttestation(e.target.value)} />
                    {!attestationValid && attestationValid !== null && <span className='text-danger'>Le champ obligatoire</span>}
                  </FormControl>
                </Grid>
              </Grid>
              <CardActions sx={{ gridColumn: '1/-1' }}>
                <Button variant="soft" color="primary" onClick={handleSubmit}>
                  Vérifier
                </Button>
              </CardActions>
            </CardContent>

          </Card>
        </div>
        <div className='col-md-2 col-xl-3'></div>
      </div>

      {showInfo && <div className={`${showInfo ? 'infoAfterSearch info row' : 'info row'}`}>
        <div className='col-md-2 col-xl-3'></div>
        <div className='col-md-8 col-xl-6'>
          <Card
            className=" shadow text-center"
            variant="outlined"
            sx={{
              // maxHeight: 'max-content',
              maxWidth: '100%',
              width: '100%'
            }}
          >
            <div className="d-flex justify-content-center">
              <Typography className=" text-center" level="title-lg" startDecorator={<InfoIcon />}>
                Informations de l'affilié
              </Typography>
            </div>
            <Divider inset="none" />
            <CardContent

            >

              <Grid container spacing={2} sx={{ flexGrow: 1 }} columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid xs={12} md={4}>
                  <AspectRatio
                    variant="outlined"
                    color="primary"
                    ratio="1"
                    sx={{
                      m: 'auto',
                      borderRadius: '50%',
                      width: 'var(--icon-size)',
                      boxShadow: 'sm',
                      bgcolor: 'background.primary',
                      position: 'relative',
                      '--icon-size': '180px'
                    }}
                  >
                    <div>
                      <img src={`https://cciso.dyndns-server.com/iconnect/api/clientImage/${data.client.salt}`} alt='profile' />
                    </div>
                  </AspectRatio>
                </Grid>
                <Grid xs={12} md={8} container>
                  <Grid xs={12}>
                    <TextField
                      value={data.client.nom}
                      label="Nom et prenom"
                      InputProps={{
                        readOnly: true,
                      }}
                      fullWidth
                      id="outlined-disabled" />
                  </Grid>
                  <Grid xs={6} >
                    <div className='d-flex justify-content-between '>
                      <FormControl className="w-100">
                        <TextField
                          value={data.client.cin}
                          label="N° CIN"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          id="outlined-disabled" />
                      </FormControl>
                    </div>

                  </Grid>
                  <Grid xs={6}>
                    <div className='d-flex justify-content-between '>
                      <FormControl className="w-100">
                        <TextField
                          value={data.client.taxeProfessionnel}
                          label="Taxe professionnelle"
                          InputProps={{
                            readOnly: true,
                          }}
                          fullWidth
                          id="outlined-disabled" />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid xs={12}>

                    <Button variant="soft" className='w-100 ' color={`${axp ? 'danger' : 'success'}`}>
                      {axp ? 'Date d\'expiration' : 'valable jusqu\'à'}: {expDate}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

            </CardContent>

          </Card>
        </div>
        <div className='col-md-2 col-xl-3'></div>
      </div>}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openNotFound}
      // onClick={handleCloseNotFound}
      >
        <div className='text-center'>
          <img src={NotFound} alt='not-found' />
          <p className='h4'>Aucun document n'est trouvé</p>
        </div>
      </Backdrop>
    </>
  );
} 